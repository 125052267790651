import React from 'react';
import logo from './assets/logo.jpg';
import logoext from './assets/azure-translator.jpeg';
import screenshot from './assets/screenshot-1.png';
import './App.scss';
import { Badge, Button, Image, Stack } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <div className='App-title'>NOBEARD</div>
          Računalne djelatnosti / IT consulting and services
          <br />
          vl. / owned by: <b>Ratko Ćosić</b>
          <br />
          OIB / VAT ID: <b>36673788357</b>
        </div>
        <div>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div>
          <i>Mentoring teams, architecting dreams ...</i>
          <span className='App-motto'>
            It's all about people. I invest time and effort, working with passion, in helping organizations to strive for knowledge, 
            technology skills and encouraging teams to use their full potential, working on great projects. 
            I base my professional and personal life believing in God and his eternal wisdom, 
            to support and understand people and making their lives fruitful and purposeful.
          </span>
        </div>
      </header>
      <body className='App-body'>       
        <p className='p-3'>My recent work:</p>
        <Stack gap={5}>         
          <div className="App-article">
            <div>
              <Image src={logoext} rounded />
            </div>
            <div className='p-2'>
              <h1>Azure Translator API Action Button</h1>
              <Badge bg="secondary" className='m-2'>Azure DevOps</Badge>
              <Badge >Azure AI Translator API</Badge>
              <article>A configurable extension that provides an action button for translating fields by using Azure AI Translator API.</article>
            </div>
            <Image src={screenshot} thumbnail />
            <Button variant="outline-warning" size="sm" className='m-2' onClick={() => window.open('https://marketplace.visualstudio.com/items?itemName=NoBeard.azure-translator-api-extension', 'blank')}>Go to Azure Marketplace</Button>
          </div>
        </Stack>
      </body>
      <footer className='App-footer'>
        <div>
          LinkedIn profiles:<br/>
          <a className="App-link" href='https://www.linkedin.com/company/nobeard/about/?viewAsMember=true' target='_blank' rel="noopener noreferrer">NoBeard company</a>
          <br />
          <a className="App-link" href='https://www.linkedin.com/in/ratko-cosic/' target='_blank' rel="noopener noreferrer">Ratko Ćosić</a>
        </div>
        <div>
          Location:<br/>
          Kružna 14, Beletinec
          <br />
          42214 Sveti Ilija, HR
        </div>
        <div>
          Contact via e-mail:<br/>
          <a href="mailto:ratko@nobeardhrvatska.onmicrosoft.com" className="App-link">ratko@nobeardhrvatska.onmicrosoft.com</a>
          <br />
          <a href="mailto:ratko_cosic@hotmail.com" className="App-link">ratko_cosic@hotmail.com</a>
        </div>       
      </footer>
    </div>
  );
}

export default App;
